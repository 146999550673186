import "../_subscribe/style.scss"

import { useState } from "react";
import { useTranslation } from "react-i18next";

import Email from "../../FormControl/InputField/_email";
import { ErrorFooterTextDefault } from "../../Notice/constantMessage";
// import Heading from "../../Heading";
import Notice from "../../Notice";
import PrivacyCheckbox from "../../FormControl/CheckboxNew/_privacy";
import PropTypes from "prop-types";
import SubmitButton from "../../Buttons/Submit";
import SuccessfullySubmit from "../../Notice/_successfullySubmit";
import { createClassName } from "../../../helpers";
import { defaultDataMapping } from "../../Api_request/dataMapping";
import { sendRequest } from "../../Api_request";
import { useForm } from "react-hook-form";
import TranslationWord from "../../Translate_component";

const cn = createClassName("subscribe-form");

const FreeTrialForm = props => {
  const { t } = useTranslation("forms");
  const [isSend, getSending] = useState(false);
  const [isServerResponse, setServerResponse] = useState(true);
  const [isLoading, setLoading] = useState(false);

  const { register, handleSubmit, errors, watch } = useForm({
    defaultValues: {
      AcceptGuides: false,
    },
  });

  const onSubmit = data => {
    setLoading(true);
    console.log(data);
    sendRequest(
      "licenses/trial",
      defaultDataMapping(data),
      getSending,
      setServerResponse,
      setLoading
    );
  };

  const successMessage = (
    <SuccessfullySubmit
      id="free trial form success"
      mailerAddress={watch("email")}
      nextStepClassName={cn("message", { success: true })}
      sendNewData={() => getSending(false)}
    />
  );

  const errorMessage = (
    <Notice
      id="free trial form error"
      withButton={false}
      errorFooterText={ErrorFooterTextDefault(props)}
      className={props.messageClassName}
    />
  );

  return isSend ? (
    successMessage
  ) : (
      <form onSubmit={handleSubmit(onSubmit)} className={props.classNameForm}>
        {/* {props.heading && props.heading} */}
        {/* <Heading level={2} weight="light" fontLevel={4} className={cn("heading")}>
          <Trans i18nKey="forms:free-trial-form.heading">
            Download and start your 14-day free trial. <br /> Full version. No
          credit card required.
        </Trans>
        </Heading> */}
        {!isServerResponse && errorMessage}
        <div className={cn("input-field", { wrap: true })}>
          <div className={cn("input-field", { email: true })}>
            <Email
              fieldClassName={cn("input-field")}
              errors={errors}
              register={register}
              isErrorState={errors?.email && true}
              name="email"
            />
          </div>
          <SubmitButton
            buttonTextKey={<TranslationWord text={isServerResponse ? "start-ft" : "try-again"} />}
            className={`${cn("button", { error: Object.keys(errors).length > 0 })} ${props.buttonClassName}`}
            isLoading={isLoading}
            spinerWrapClassName={isLoading ? props.spinerWrapClassName : ""}
          />
        </div>
        <PrivacyCheckbox
          license
          name="AcceptGuides"
          className={cn("checkbox")}
          tooltipContent={t("tooltip.privacy")}
          register={register({ required: true })}
          isErrorState={errors?.AcceptGuides && true}
        />
      </form>
    );
};

FreeTrialForm.defaultProps = {
  buttonClassName: "",
  emailClassName: "",
  spinerWrapClassName: "",
};

FreeTrialForm.propTypes = {
  classNameForm: PropTypes.string,
  buttonClassName: PropTypes.string,
  emailClassName: PropTypes.string,
  messageClassName: PropTypes.string,
  heading: PropTypes.object,
  spinerWrapClassName: PropTypes.string,
};

export default FreeTrialForm;

import PropTypes from "prop-types";
import Icon from "./index";

const MSExxelLogo = (props) => Icon({
    ...props,
    iconName: "system-requirements-ms-excel-versions",
  });

MSExxelLogo.defaultProps = {
  isAriaHidden: false,
  // alt: "oops, page 404",
};

MSExxelLogo.propTypes = {
  alt: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  isAriaHidden: PropTypes.bool,
  className: PropTypes.string,
};

export default MSExxelLogo;

import PropTypes from "prop-types";
import Icon from "./index";

const WindowsLogo = (props) => Icon({
    ...props,
    iconName: "system-requirements-windows-versions",
  });

WindowsLogo.defaultProps = {
  isAriaHidden: false,
  // alt: "oops, page 404",
};

WindowsLogo.propTypes = {
  alt: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  isAriaHidden: PropTypes.bool,
  className: PropTypes.string,
};

export default WindowsLogo;

import "./style.scss";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next/dist/es/useTranslation";
import MSExcelLogo from "../Icons/MSExcelLogo";
import WindowsLogo from "../Icons/WindowsLogo";
import { createClassName } from "../../helpers";

const cn = createClassName("requirements");

const SystemRequirements = (props) => {
  const { t } = useTranslation("common");

  const requirementsList = [
    {
      title: <>MS Excel 2010 &#8212; 2021</>,
      bit: (
        <>
          32-
          {t("bit")}
          {" "}
          {t("and")}
          {" "}
          64-
          {t("bit")}
        </>
      ),
      img: (
        <MSExcelLogo
          alt={`${t("for_upper")} Excel 2019, 2016, 2013, 2010`}
        />
      ),
    },
    {
      title: <>Windows 7 &#8212; 11</>,
      bit: (
        <>
          32-
          {t("bit")}
          {" "}
          {t("and")}
          {" "}
          64-
          {t("bit")}
        </>
      ),
      img: <WindowsLogo alt={`${t("for_upper")} Windows 11, 10, 8.1, 8, 7`} />,
    },
  ];

  const systemRequirements = requirementsList.map((item, index) => (
    <li
      key={index}
      className={`${cn({ item: "wrap" })} ${props.itemClassName}`}
    >
      <div className={cn({ item: "data" })}>
        <div className={cn({ item: "logo" })}>{item.img}</div>
        <div className={cn({ item: "body" })}>
          <em className={cn({ item: "title" })}>{item.title}</em>
          <span className={cn({ item: "bit" })}>{item.bit}</span>
        </div>
      </div>
    </li>
  ));

  return (
    <ul className={`${cn({ list: true })} ${props.listClassName}`}>
      {systemRequirements}
    </ul>
  );
};

SystemRequirements.defaultProps = {
  listClassName: "",
};

SystemRequirements.propTypes = {
  itemClassName: PropTypes.string,
  listClassName: PropTypes.string,
};

export default SystemRequirements;
